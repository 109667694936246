import React from 'react'

// https://medium.com/@BogdanSoare/how-to-use-reacts-new-context-api-to-easily-manage-modals-2ae45c7def81
export const ModalContext = React.createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
})

export const ModalConsumer = ModalContext.Consumer;

export const hideOverflow = () => {
  document.body.style.overflow = 'hidden'
}
export const showOverflow = () => {
  document.body.style.overflow = 'auto'
}

export class ModalProvider extends React.Component {
  showModal = (component, props = {}) => {
    hideOverflow()
    this.setState({
      component,
      props
    });
  }
  hideModal = () => {
    showOverflow()
    this.setState({
      component: null,
      props: {},
    })
  }
  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal
  }
  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} onRequestClose={hideModal} /> : null
    }
  </ModalConsumer>
)
