import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { SITE_TITLE, SITE_DESCRIPTION } from 'components/site-title'
import styled from 'styled-components'

import { ModalProvider, ModalRoot } from 'components/modal'

import './layout.css'

const LayoutScroll = styled('div')`
  overflow-x: hidden;
`

const Layout = ({ children, fullHeight }) => {
  return (
    <>
      <Helmet
        title={SITE_TITLE}
        description={SITE_DESCRIPTION}
      >
        <html
        lang="en" />
      </Helmet>
      <LayoutScroll>
        <ModalProvider>
          <ModalRoot />
          <div id="root" className={fullHeight ? 'fullHeight' : ''}>
            {children}
          </div>
        </ModalProvider>
      </LayoutScroll>
    </>
  )
}

export default Layout
