import React from 'react'

import Layout from 'components/layout'

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export default class Unregister extends React.Component {
  componentDidMount() {
    unregister()
  }
  render() {
    return (
      <Layout>
        Last build time: {this.props.pageContext.lastBuildTime}
      </Layout>
    )
  }
}
